/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type CompetitionState =
  (typeof CompetitionState)[keyof typeof CompetitionState];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompetitionState = {
  draft: "draft",
  ready: "ready",
  active: "active",
  finalizing: "finalizing",
  completed: "completed",
} as const;
