/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type OutboxRecordState =
  (typeof OutboxRecordState)[keyof typeof OutboxRecordState];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OutboxRecordState = {
  pending: "pending",
  processed: "processed",
  failed: "failed",
} as const;
