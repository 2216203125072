/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  CommMethodListSchema,
  CommMethodSchema,
  HTTPValidationError,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Get all communication methods
 * @summary Get Comm Methods
 */
export const getCommMethods = (
  tenantUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CommMethodListSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/comm-methods`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetCommMethodsQueryKey = (tenantUuid: string) => {
  return [`/api/v1/tenants/${tenantUuid}/lead-gen/comm-methods`] as const;
};

export const getGetCommMethodsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCommMethods>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCommMethods>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCommMethodsQueryKey(tenantUuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommMethods>>> = ({
    signal,
  }) => getCommMethods(tenantUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCommMethods>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCommMethodsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCommMethods>>
>;
export type GetCommMethodsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Comm Methods
 */

export function useGetCommMethods<
  TData = Awaited<ReturnType<typeof getCommMethods>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCommMethods>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCommMethodsQueryOptions(tenantUuid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get a specific communication method by UUID
 * @summary Get Comm Method
 */
export const getCommMethod = (
  tenantUuid: string,
  commMethodUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CommMethodSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/comm-methods/${commMethodUuid}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetCommMethodQueryKey = (
  tenantUuid: string,
  commMethodUuid: string
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/comm-methods/${commMethodUuid}`,
  ] as const;
};

export const getGetCommMethodQueryOptions = <
  TData = Awaited<ReturnType<typeof getCommMethod>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  commMethodUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCommMethod>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCommMethodQueryKey(tenantUuid, commMethodUuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommMethod>>> = ({
    signal,
  }) => getCommMethod(tenantUuid, commMethodUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && commMethodUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCommMethod>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCommMethodQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCommMethod>>
>;
export type GetCommMethodQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Comm Method
 */

export function useGetCommMethod<
  TData = Awaited<ReturnType<typeof getCommMethod>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  commMethodUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCommMethod>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCommMethodQueryOptions(
    tenantUuid,
    commMethodUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
