/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation } from "@tanstack/react-query";
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import type {
  BehaviorConfigSchema,
  CreateBehaviorBody,
  HTTPValidationError,
  UpdateBehaviorBody,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Create Behavior
 */
export const createBehavior = (
  tenantUuid: string,
  createBehaviorBody: CreateBehaviorBody,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BehaviorConfigSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/behaviors`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createBehaviorBody,
      signal,
    },
    options
  );
};

export const getCreateBehaviorMutationOptions = <
  TData = Awaited<ReturnType<typeof createBehavior>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: CreateBehaviorBody },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["createBehavior"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createBehavior>>,
    { tenantUuid: string; data: CreateBehaviorBody }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return createBehavior(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: CreateBehaviorBody },
    TContext
  >;
};

export type CreateBehaviorMutationResult = NonNullable<
  Awaited<ReturnType<typeof createBehavior>>
>;
export type CreateBehaviorMutationBody = CreateBehaviorBody;
export type CreateBehaviorMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Create Behavior
 */
export const useCreateBehavior = <
  TData = Awaited<ReturnType<typeof createBehavior>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: CreateBehaviorBody },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; data: CreateBehaviorBody },
  TContext
> => {
  const mutationOptions = getCreateBehaviorMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Update Behavior
 */
export const updateBehavior = (
  tenantUuid: string,
  behaviorUuid: string,
  updateBehaviorBody: UpdateBehaviorBody,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<BehaviorConfigSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/behaviors/${behaviorUuid}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateBehaviorBody,
    },
    options
  );
};

export const getUpdateBehaviorMutationOptions = <
  TData = Awaited<ReturnType<typeof updateBehavior>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; behaviorUuid: string; data: UpdateBehaviorBody },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["updateBehavior"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateBehavior>>,
    { tenantUuid: string; behaviorUuid: string; data: UpdateBehaviorBody }
  > = (props) => {
    const { tenantUuid, behaviorUuid, data } = props ?? {};

    return updateBehavior(tenantUuid, behaviorUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; behaviorUuid: string; data: UpdateBehaviorBody },
    TContext
  >;
};

export type UpdateBehaviorMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateBehavior>>
>;
export type UpdateBehaviorMutationBody = UpdateBehaviorBody;
export type UpdateBehaviorMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update Behavior
 */
export const useUpdateBehavior = <
  TData = Awaited<ReturnType<typeof updateBehavior>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; behaviorUuid: string; data: UpdateBehaviorBody },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; behaviorUuid: string; data: UpdateBehaviorBody },
  TContext
> => {
  const mutationOptions = getUpdateBehaviorMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Delete Behavior
 */
export const deleteBehavior = (
  tenantUuid: string,
  behaviorUuid: string,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/behaviors/${behaviorUuid}`,
      method: "DELETE",
    },
    options
  );
};

export const getDeleteBehaviorMutationOptions = <
  TData = Awaited<ReturnType<typeof deleteBehavior>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; behaviorUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["deleteBehavior"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteBehavior>>,
    { tenantUuid: string; behaviorUuid: string }
  > = (props) => {
    const { tenantUuid, behaviorUuid } = props ?? {};

    return deleteBehavior(tenantUuid, behaviorUuid, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; behaviorUuid: string },
    TContext
  >;
};

export type DeleteBehaviorMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteBehavior>>
>;

export type DeleteBehaviorMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Delete Behavior
 */
export const useDeleteBehavior = <
  TData = Awaited<ReturnType<typeof deleteBehavior>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; behaviorUuid: string },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; behaviorUuid: string },
  TContext
> => {
  const mutationOptions = getDeleteBehaviorMutationOptions(options);

  return useMutation(mutationOptions);
};
