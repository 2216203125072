/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type { GetJobTypesResponse, HTTPValidationError } from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get Job Types
 */
export const getJobTypes = (
  tenantUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<GetJobTypesResponse>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/jobs/types`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetJobTypesQueryKey = (tenantUuid: string) => {
  return [`/api/v1/tenants/${tenantUuid}/lead-gen/jobs/types`] as const;
};

export const getGetJobTypesQueryOptions = <
  TData = Awaited<ReturnType<typeof getJobTypes>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getJobTypes>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetJobTypesQueryKey(tenantUuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getJobTypes>>> = ({
    signal,
  }) => getJobTypes(tenantUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getJobTypes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetJobTypesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getJobTypes>>
>;
export type GetJobTypesQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Job Types
 */

export function useGetJobTypes<
  TData = Awaited<ReturnType<typeof getJobTypes>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getJobTypes>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetJobTypesQueryOptions(tenantUuid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
