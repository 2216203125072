/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  AudienceCitiesResponse,
  AudienceFilterSchemaInput,
  AudienceSchema,
  AudienceSearchResultSchema,
  AudienceStatesResponse,
  AudienceZipCodesResponse,
  HTTPValidationError,
  SearchAudiencesParams,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Search Audiences
 */
export const searchAudiences = (
  tenantUuid: string,
  params?: SearchAudiencesParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<AudienceSearchResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/audiences/search`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSearchAudiencesQueryKey = (
  tenantUuid: string,
  params?: SearchAudiencesParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/audiences/search`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchAudiencesQueryOptions = <
  TData = Awaited<ReturnType<typeof searchAudiences>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: SearchAudiencesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchAudiences>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSearchAudiencesQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchAudiences>>> = ({
    signal,
  }) => searchAudiences(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchAudiences>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchAudiencesQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchAudiences>>
>;
export type SearchAudiencesQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Search Audiences
 */

export function useSearchAudiences<
  TData = Awaited<ReturnType<typeof searchAudiences>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: SearchAudiencesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchAudiences>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchAudiencesQueryOptions(
    tenantUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Init Audience
 */
export const getInitAudience = (
  tenantUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<AudienceSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/audiences/init`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetInitAudienceQueryKey = (tenantUuid: string) => {
  return [`/api/v1/tenants/${tenantUuid}/lead-gen/audiences/init`] as const;
};

export const getGetInitAudienceQueryOptions = <
  TData = Awaited<ReturnType<typeof getInitAudience>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInitAudience>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetInitAudienceQueryKey(tenantUuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInitAudience>>> = ({
    signal,
  }) => getInitAudience(tenantUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getInitAudience>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetInitAudienceQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInitAudience>>
>;
export type GetInitAudienceQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Init Audience
 */

export function useGetInitAudience<
  TData = Awaited<ReturnType<typeof getInitAudience>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInitAudience>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetInitAudienceQueryOptions(tenantUuid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get audience based on filter criteria
 * @summary Refresh Audience
 */
export const refreshAudience = (
  tenantUuid: string,
  audienceFilterSchemaInput: AudienceFilterSchemaInput,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<AudienceSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/audiences/refresh`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: audienceFilterSchemaInput,
      signal,
    },
    options
  );
};

export const getRefreshAudienceMutationOptions = <
  TData = Awaited<ReturnType<typeof refreshAudience>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: AudienceFilterSchemaInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["refreshAudience"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof refreshAudience>>,
    { tenantUuid: string; data: AudienceFilterSchemaInput }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return refreshAudience(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: AudienceFilterSchemaInput },
    TContext
  >;
};

export type RefreshAudienceMutationResult = NonNullable<
  Awaited<ReturnType<typeof refreshAudience>>
>;
export type RefreshAudienceMutationBody = AudienceFilterSchemaInput;
export type RefreshAudienceMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Refresh Audience
 */
export const useRefreshAudience = <
  TData = Awaited<ReturnType<typeof refreshAudience>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: AudienceFilterSchemaInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; data: AudienceFilterSchemaInput },
  TContext
> => {
  const mutationOptions = getRefreshAudienceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Export the entire audience to CSV
 */
export const exportAudience = (
  tenantUuid: string,
  audienceFilterSchemaInput: AudienceFilterSchemaInput,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<void>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-genaudiences/export`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: audienceFilterSchemaInput,
      signal,
    },
    options
  );
};

export const getExportAudienceMutationOptions = <
  TData = Awaited<ReturnType<typeof exportAudience>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: AudienceFilterSchemaInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["exportAudience"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof exportAudience>>,
    { tenantUuid: string; data: AudienceFilterSchemaInput }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return exportAudience(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: AudienceFilterSchemaInput },
    TContext
  >;
};

export type ExportAudienceMutationResult = NonNullable<
  Awaited<ReturnType<typeof exportAudience>>
>;
export type ExportAudienceMutationBody = AudienceFilterSchemaInput;
export type ExportAudienceMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Export the entire audience to CSV
 */
export const useExportAudience = <
  TData = Awaited<ReturnType<typeof exportAudience>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: AudienceFilterSchemaInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; data: AudienceFilterSchemaInput },
  TContext
> => {
  const mutationOptions = getExportAudienceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Audience Zip Codes
 */
export const getAudienceZipCodes = (
  tenantUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<AudienceZipCodesResponse>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/audiences/zip`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetAudienceZipCodesQueryKey = (tenantUuid: string) => {
  return [`/api/v1/tenants/${tenantUuid}/lead-gen/audiences/zip`] as const;
};

export const getGetAudienceZipCodesQueryOptions = <
  TData = Awaited<ReturnType<typeof getAudienceZipCodes>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAudienceZipCodes>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAudienceZipCodesQueryKey(tenantUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAudienceZipCodes>>
  > = ({ signal }) => getAudienceZipCodes(tenantUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAudienceZipCodes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAudienceZipCodesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAudienceZipCodes>>
>;
export type GetAudienceZipCodesQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Audience Zip Codes
 */

export function useGetAudienceZipCodes<
  TData = Awaited<ReturnType<typeof getAudienceZipCodes>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAudienceZipCodes>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAudienceZipCodesQueryOptions(tenantUuid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Audience Cities
 */
export const getAudienceCities = (
  tenantUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<AudienceCitiesResponse>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/audiences/city`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetAudienceCitiesQueryKey = (tenantUuid: string) => {
  return [`/api/v1/tenants/${tenantUuid}/lead-gen/audiences/city`] as const;
};

export const getGetAudienceCitiesQueryOptions = <
  TData = Awaited<ReturnType<typeof getAudienceCities>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAudienceCities>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAudienceCitiesQueryKey(tenantUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAudienceCities>>
  > = ({ signal }) => getAudienceCities(tenantUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAudienceCities>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAudienceCitiesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAudienceCities>>
>;
export type GetAudienceCitiesQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Audience Cities
 */

export function useGetAudienceCities<
  TData = Awaited<ReturnType<typeof getAudienceCities>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAudienceCities>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAudienceCitiesQueryOptions(tenantUuid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Audience States
 */
export const getAudienceStates = (
  tenantUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<AudienceStatesResponse>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/audiences/state`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetAudienceStatesQueryKey = (tenantUuid: string) => {
  return [`/api/v1/tenants/${tenantUuid}/lead-gen/audiences/state`] as const;
};

export const getGetAudienceStatesQueryOptions = <
  TData = Awaited<ReturnType<typeof getAudienceStates>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAudienceStates>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAudienceStatesQueryKey(tenantUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAudienceStates>>
  > = ({ signal }) => getAudienceStates(tenantUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAudienceStates>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAudienceStatesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAudienceStates>>
>;
export type GetAudienceStatesQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Audience States
 */

export function useGetAudienceStates<
  TData = Awaited<ReturnType<typeof getAudienceStates>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAudienceStates>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetAudienceStatesQueryOptions(tenantUuid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
