/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation } from "@tanstack/react-query";
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Receive Twilio Sms Message
 */
export const receiveTwilioSmsMessage = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<unknown>(
    { url: `/api/v1/callbacks/sms-message`, method: "POST", signal },
    options
  );
};

export const getReceiveTwilioSmsMessageMutationOptions = <
  TData = Awaited<ReturnType<typeof receiveTwilioSmsMessage>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, void, TContext>;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["receiveTwilioSmsMessage"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof receiveTwilioSmsMessage>>,
    void
  > = () => {
    return receiveTwilioSmsMessage(requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    void,
    TContext
  >;
};

export type ReceiveTwilioSmsMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof receiveTwilioSmsMessage>>
>;

export type ReceiveTwilioSmsMessageMutationError = ErrorType<unknown>;

/**
 * @summary Receive Twilio Sms Message
 */
export const useReceiveTwilioSmsMessage = <
  TData = Awaited<ReturnType<typeof receiveTwilioSmsMessage>>,
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<TData, TError, void, TContext>;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<TData, TError, void, TContext> => {
  const mutationOptions = getReceiveTwilioSmsMessageMutationOptions(options);

  return useMutation(mutationOptions);
};
