/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  BodyImageResourceGenerateAvatarApiV1TenantsTenantUuidBoostImagesGenerateAvatarPost,
  BodyImageResourceUploadImageApiV1TenantsTenantUuidBoostImagesUploadPost,
  HTTPValidationError,
  ImageSchema,
  SearchImageResultSchema,
  SearchImagesParams,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Upload Image
 */
export const uploadImage = (
  tenantUuid: string,
  bodyImageResourceUploadImageApiV1TenantsTenantUuidBoostImagesUploadPost: BodyImageResourceUploadImageApiV1TenantsTenantUuidBoostImagesUploadPost,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  const formData = new FormData();
  formData.append(
    "data",
    bodyImageResourceUploadImageApiV1TenantsTenantUuidBoostImagesUploadPost.data
  );

  return axiosInstance<ImageSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/images/upload`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
      signal,
    },
    options
  );
};

export const getUploadImageMutationOptions = <
  TData = Awaited<ReturnType<typeof uploadImage>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      data: BodyImageResourceUploadImageApiV1TenantsTenantUuidBoostImagesUploadPost;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["uploadImage"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadImage>>,
    {
      tenantUuid: string;
      data: BodyImageResourceUploadImageApiV1TenantsTenantUuidBoostImagesUploadPost;
    }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return uploadImage(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      data: BodyImageResourceUploadImageApiV1TenantsTenantUuidBoostImagesUploadPost;
    },
    TContext
  >;
};

export type UploadImageMutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadImage>>
>;
export type UploadImageMutationBody =
  BodyImageResourceUploadImageApiV1TenantsTenantUuidBoostImagesUploadPost;
export type UploadImageMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Upload Image
 */
export const useUploadImage = <
  TData = Awaited<ReturnType<typeof uploadImage>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      data: BodyImageResourceUploadImageApiV1TenantsTenantUuidBoostImagesUploadPost;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  {
    tenantUuid: string;
    data: BodyImageResourceUploadImageApiV1TenantsTenantUuidBoostImagesUploadPost;
  },
  TContext
> => {
  const mutationOptions = getUploadImageMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Search Images
 */
export const searchImages = (
  tenantUuid: string,
  params: SearchImagesParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<SearchImageResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/images/search`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSearchImagesQueryKey = (
  tenantUuid: string,
  params: SearchImagesParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/images/search`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchImagesQueryOptions = <
  TData = Awaited<ReturnType<typeof searchImages>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params: SearchImagesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchImages>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSearchImagesQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchImages>>> = ({
    signal,
  }) => searchImages(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchImages>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchImagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchImages>>
>;
export type SearchImagesQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Search Images
 */

export function useSearchImages<
  TData = Awaited<ReturnType<typeof searchImages>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params: SearchImagesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchImages>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchImagesQueryOptions(tenantUuid, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Generate Avatar
 */
export const generateAvatar = (
  tenantUuid: string,
  bodyImageResourceGenerateAvatarApiV1TenantsTenantUuidBoostImagesGenerateAvatarPost: BodyImageResourceGenerateAvatarApiV1TenantsTenantUuidBoostImagesGenerateAvatarPost,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  const formData = new FormData();
  formData.append(
    "data",
    bodyImageResourceGenerateAvatarApiV1TenantsTenantUuidBoostImagesGenerateAvatarPost.data
  );

  return axiosInstance<unknown>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/images/generate-avatar`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
      signal,
    },
    options
  );
};

export const getGenerateAvatarMutationOptions = <
  TData = Awaited<ReturnType<typeof generateAvatar>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      data: BodyImageResourceGenerateAvatarApiV1TenantsTenantUuidBoostImagesGenerateAvatarPost;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["generateAvatar"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof generateAvatar>>,
    {
      tenantUuid: string;
      data: BodyImageResourceGenerateAvatarApiV1TenantsTenantUuidBoostImagesGenerateAvatarPost;
    }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return generateAvatar(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      data: BodyImageResourceGenerateAvatarApiV1TenantsTenantUuidBoostImagesGenerateAvatarPost;
    },
    TContext
  >;
};

export type GenerateAvatarMutationResult = NonNullable<
  Awaited<ReturnType<typeof generateAvatar>>
>;
export type GenerateAvatarMutationBody =
  BodyImageResourceGenerateAvatarApiV1TenantsTenantUuidBoostImagesGenerateAvatarPost;
export type GenerateAvatarMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Generate Avatar
 */
export const useGenerateAvatar = <
  TData = Awaited<ReturnType<typeof generateAvatar>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      data: BodyImageResourceGenerateAvatarApiV1TenantsTenantUuidBoostImagesGenerateAvatarPost;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  {
    tenantUuid: string;
    data: BodyImageResourceGenerateAvatarApiV1TenantsTenantUuidBoostImagesGenerateAvatarPost;
  },
  TContext
> => {
  const mutationOptions = getGenerateAvatarMutationOptions(options);

  return useMutation(mutationOptions);
};
