/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  BodyTenantOrganizationResourceValidateBulkUploadUsersApiV1TenantsTenantUuidBoostOrganizationsOrgUuidActionsValidateBulkUserUploadPost,
  BoostCreateTechniciansFromERPUsersInputSchema,
  BoostSearchERPUserResultSchema,
  BoostSearchOrganizationsResultSchema,
  BoostUserSchema,
  HTTPValidationError,
  SearchErpUsersParams,
  SearchOrganizationsParams,
  TenantOrganization,
  TenantOrganizationDetailsSchema,
  ValidBulkUserUploadResponse,
  ValidateBulkUploadUsers200,
  ValidatedUserUploadKey,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get Current Tenant Organization
 */
export const getCurrentTenantOrganization = (
  tenantUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<TenantOrganizationDetailsSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/organizations/me`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetCurrentTenantOrganizationQueryKey = (tenantUuid: string) => {
  return [`/api/v1/tenants/${tenantUuid}/boost/organizations/me`] as const;
};

export const getGetCurrentTenantOrganizationQueryOptions = <
  TData = Awaited<ReturnType<typeof getCurrentTenantOrganization>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCurrentTenantOrganization>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCurrentTenantOrganizationQueryKey(tenantUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCurrentTenantOrganization>>
  > = ({ signal }) =>
    getCurrentTenantOrganization(tenantUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCurrentTenantOrganization>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCurrentTenantOrganizationQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCurrentTenantOrganization>>
>;
export type GetCurrentTenantOrganizationQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Get Current Tenant Organization
 */

export function useGetCurrentTenantOrganization<
  TData = Awaited<ReturnType<typeof getCurrentTenantOrganization>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCurrentTenantOrganization>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCurrentTenantOrganizationQueryOptions(
    tenantUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Search Organizations
 */
export const searchOrganizations = (
  tenantUuid: string,
  params?: SearchOrganizationsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostSearchOrganizationsResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/organizations/search`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSearchOrganizationsQueryKey = (
  tenantUuid: string,
  params?: SearchOrganizationsParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/organizations/search`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchOrganizationsQueryOptions = <
  TData = Awaited<ReturnType<typeof searchOrganizations>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: SearchOrganizationsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchOrganizations>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSearchOrganizationsQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchOrganizations>>
  > = ({ signal }) =>
    searchOrganizations(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchOrganizations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchOrganizationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchOrganizations>>
>;
export type SearchOrganizationsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Search Organizations
 */

export function useSearchOrganizations<
  TData = Awaited<ReturnType<typeof searchOrganizations>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: SearchOrganizationsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchOrganizations>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchOrganizationsQueryOptions(
    tenantUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Tenant Organization By Uuid
 */
export const getTenantOrganizationByUuid = (
  tenantUuid: string,
  orgUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<TenantOrganization>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/organizations/${orgUuid}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetTenantOrganizationByUuidQueryKey = (
  tenantUuid: string,
  orgUuid: string
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/organizations/${orgUuid}`,
  ] as const;
};

export const getGetTenantOrganizationByUuidQueryOptions = <
  TData = Awaited<ReturnType<typeof getTenantOrganizationByUuid>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  orgUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTenantOrganizationByUuid>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetTenantOrganizationByUuidQueryKey(tenantUuid, orgUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTenantOrganizationByUuid>>
  > = ({ signal }) =>
    getTenantOrganizationByUuid(tenantUuid, orgUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && orgUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getTenantOrganizationByUuid>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTenantOrganizationByUuidQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTenantOrganizationByUuid>>
>;
export type GetTenantOrganizationByUuidQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Get Tenant Organization By Uuid
 */

export function useGetTenantOrganizationByUuid<
  TData = Awaited<ReturnType<typeof getTenantOrganizationByUuid>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  orgUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTenantOrganizationByUuid>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetTenantOrganizationByUuidQueryOptions(
    tenantUuid,
    orgUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get User Upload Template
 */
export const getUserUploadTemplate = (
  tenantUuid: string,
  orgUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<string>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/organizations/${orgUuid}/actions/download-bulk-user-upload-template`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetUserUploadTemplateQueryKey = (
  tenantUuid: string,
  orgUuid: string
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/organizations/${orgUuid}/actions/download-bulk-user-upload-template`,
  ] as const;
};

export const getGetUserUploadTemplateQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserUploadTemplate>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  orgUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserUploadTemplate>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetUserUploadTemplateQueryKey(tenantUuid, orgUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUserUploadTemplate>>
  > = ({ signal }) =>
    getUserUploadTemplate(tenantUuid, orgUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && orgUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserUploadTemplate>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserUploadTemplateQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserUploadTemplate>>
>;
export type GetUserUploadTemplateQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get User Upload Template
 */

export function useGetUserUploadTemplate<
  TData = Awaited<ReturnType<typeof getUserUploadTemplate>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  orgUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserUploadTemplate>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetUserUploadTemplateQueryOptions(
    tenantUuid,
    orgUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Validate Bulk Upload Users
 */
export const validateBulkUploadUsers = (
  tenantUuid: string,
  orgUuid: string,
  bodyTenantOrganizationResourceValidateBulkUploadUsersApiV1TenantsTenantUuidBoostOrganizationsOrgUuidActionsValidateBulkUserUploadPost: BodyTenantOrganizationResourceValidateBulkUploadUsersApiV1TenantsTenantUuidBoostOrganizationsOrgUuidActionsValidateBulkUserUploadPost,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  const formData = new FormData();
  formData.append(
    "data",
    bodyTenantOrganizationResourceValidateBulkUploadUsersApiV1TenantsTenantUuidBoostOrganizationsOrgUuidActionsValidateBulkUserUploadPost.data
  );

  return axiosInstance<ValidateBulkUploadUsers200>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/organizations/${orgUuid}/actions/validate-bulk-user-upload`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
      signal,
    },
    options
  );
};

export const getValidateBulkUploadUsersMutationOptions = <
  TData = Awaited<ReturnType<typeof validateBulkUploadUsers>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      orgUuid: string;
      data: BodyTenantOrganizationResourceValidateBulkUploadUsersApiV1TenantsTenantUuidBoostOrganizationsOrgUuidActionsValidateBulkUserUploadPost;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["validateBulkUploadUsers"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof validateBulkUploadUsers>>,
    {
      tenantUuid: string;
      orgUuid: string;
      data: BodyTenantOrganizationResourceValidateBulkUploadUsersApiV1TenantsTenantUuidBoostOrganizationsOrgUuidActionsValidateBulkUserUploadPost;
    }
  > = (props) => {
    const { tenantUuid, orgUuid, data } = props ?? {};

    return validateBulkUploadUsers(tenantUuid, orgUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      orgUuid: string;
      data: BodyTenantOrganizationResourceValidateBulkUploadUsersApiV1TenantsTenantUuidBoostOrganizationsOrgUuidActionsValidateBulkUserUploadPost;
    },
    TContext
  >;
};

export type ValidateBulkUploadUsersMutationResult = NonNullable<
  Awaited<ReturnType<typeof validateBulkUploadUsers>>
>;
export type ValidateBulkUploadUsersMutationBody =
  BodyTenantOrganizationResourceValidateBulkUploadUsersApiV1TenantsTenantUuidBoostOrganizationsOrgUuidActionsValidateBulkUserUploadPost;
export type ValidateBulkUploadUsersMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Validate Bulk Upload Users
 */
export const useValidateBulkUploadUsers = <
  TData = Awaited<ReturnType<typeof validateBulkUploadUsers>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      orgUuid: string;
      data: BodyTenantOrganizationResourceValidateBulkUploadUsersApiV1TenantsTenantUuidBoostOrganizationsOrgUuidActionsValidateBulkUserUploadPost;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  {
    tenantUuid: string;
    orgUuid: string;
    data: BodyTenantOrganizationResourceValidateBulkUploadUsersApiV1TenantsTenantUuidBoostOrganizationsOrgUuidActionsValidateBulkUserUploadPost;
  },
  TContext
> => {
  const mutationOptions = getValidateBulkUploadUsersMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Apply Bulk Upload Users
 */
export const applyBulkUploadUsers = (
  tenantUuid: string,
  orgUuid: string,
  validatedUserUploadKey: ValidatedUserUploadKey,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<ValidBulkUserUploadResponse>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/organizations/${orgUuid}/actions/apply-bulk-user-upload`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: validatedUserUploadKey,
      signal,
    },
    options
  );
};

export const getApplyBulkUploadUsersMutationOptions = <
  TData = Awaited<ReturnType<typeof applyBulkUploadUsers>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; orgUuid: string; data: ValidatedUserUploadKey },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["applyBulkUploadUsers"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof applyBulkUploadUsers>>,
    { tenantUuid: string; orgUuid: string; data: ValidatedUserUploadKey }
  > = (props) => {
    const { tenantUuid, orgUuid, data } = props ?? {};

    return applyBulkUploadUsers(tenantUuid, orgUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; orgUuid: string; data: ValidatedUserUploadKey },
    TContext
  >;
};

export type ApplyBulkUploadUsersMutationResult = NonNullable<
  Awaited<ReturnType<typeof applyBulkUploadUsers>>
>;
export type ApplyBulkUploadUsersMutationBody = ValidatedUserUploadKey;
export type ApplyBulkUploadUsersMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Apply Bulk Upload Users
 */
export const useApplyBulkUploadUsers = <
  TData = Awaited<ReturnType<typeof applyBulkUploadUsers>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; orgUuid: string; data: ValidatedUserUploadKey },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; orgUuid: string; data: ValidatedUserUploadKey },
  TContext
> => {
  const mutationOptions = getApplyBulkUploadUsersMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Search Erp Users
 */
export const searchErpUsers = (
  tenantUuid: string,
  orgUuid: string,
  params?: SearchErpUsersParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostSearchERPUserResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/organizations/${orgUuid}/actions/search-erp-users`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSearchErpUsersQueryKey = (
  tenantUuid: string,
  orgUuid: string,
  params?: SearchErpUsersParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/organizations/${orgUuid}/actions/search-erp-users`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchErpUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof searchErpUsers>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  orgUuid: string,
  params?: SearchErpUsersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchErpUsers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSearchErpUsersQueryKey(tenantUuid, orgUuid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchErpUsers>>> = ({
    signal,
  }) => searchErpUsers(tenantUuid, orgUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && orgUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchErpUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchErpUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchErpUsers>>
>;
export type SearchErpUsersQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Search Erp Users
 */

export function useSearchErpUsers<
  TData = Awaited<ReturnType<typeof searchErpUsers>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  orgUuid: string,
  params?: SearchErpUsersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchErpUsers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchErpUsersQueryOptions(
    tenantUuid,
    orgUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create Technicians From Erp Users
 */
export const createTechniciansFromErpUsers = (
  tenantUuid: string,
  orgUuid: string,
  boostCreateTechniciansFromERPUsersInputSchema: BoostCreateTechniciansFromERPUsersInputSchema,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostUserSchema[]>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/organizations/${orgUuid}/actions/create-technicians-from-erp-users`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: boostCreateTechniciansFromERPUsersInputSchema,
      signal,
    },
    options
  );
};

export const getCreateTechniciansFromErpUsersMutationOptions = <
  TData = Awaited<ReturnType<typeof createTechniciansFromErpUsers>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      orgUuid: string;
      data: BoostCreateTechniciansFromERPUsersInputSchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["createTechniciansFromErpUsers"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createTechniciansFromErpUsers>>,
    {
      tenantUuid: string;
      orgUuid: string;
      data: BoostCreateTechniciansFromERPUsersInputSchema;
    }
  > = (props) => {
    const { tenantUuid, orgUuid, data } = props ?? {};

    return createTechniciansFromErpUsers(
      tenantUuid,
      orgUuid,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      orgUuid: string;
      data: BoostCreateTechniciansFromERPUsersInputSchema;
    },
    TContext
  >;
};

export type CreateTechniciansFromErpUsersMutationResult = NonNullable<
  Awaited<ReturnType<typeof createTechniciansFromErpUsers>>
>;
export type CreateTechniciansFromErpUsersMutationBody =
  BoostCreateTechniciansFromERPUsersInputSchema;
export type CreateTechniciansFromErpUsersMutationError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Create Technicians From Erp Users
 */
export const useCreateTechniciansFromErpUsers = <
  TData = Awaited<ReturnType<typeof createTechniciansFromErpUsers>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    {
      tenantUuid: string;
      orgUuid: string;
      data: BoostCreateTechniciansFromERPUsersInputSchema;
    },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  {
    tenantUuid: string;
    orgUuid: string;
    data: BoostCreateTechniciansFromERPUsersInputSchema;
  },
  TContext
> => {
  const mutationOptions =
    getCreateTechniciansFromErpUsersMutationOptions(options);

  return useMutation(mutationOptions);
};
