/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  FeatureAccessSchema,
  FeatureName,
  HTTPValidationError,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Check if the authenticated tenant has access to a specific feature
 * @summary Check Feature Access
 */
export const checkFeatureAccess = (
  tenantUuid: string,
  featureName: FeatureName,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<FeatureAccessSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/features/${featureName}/access`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getCheckFeatureAccessQueryKey = (
  tenantUuid: string,
  featureName: FeatureName
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/features/${featureName}/access`,
  ] as const;
};

export const getCheckFeatureAccessQueryOptions = <
  TData = Awaited<ReturnType<typeof checkFeatureAccess>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  featureName: FeatureName,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof checkFeatureAccess>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCheckFeatureAccessQueryKey(tenantUuid, featureName);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof checkFeatureAccess>>
  > = ({ signal }) =>
    checkFeatureAccess(tenantUuid, featureName, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && featureName),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof checkFeatureAccess>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CheckFeatureAccessQueryResult = NonNullable<
  Awaited<ReturnType<typeof checkFeatureAccess>>
>;
export type CheckFeatureAccessQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Check Feature Access
 */

export function useCheckFeatureAccess<
  TData = Awaited<ReturnType<typeof checkFeatureAccess>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  featureName: FeatureName,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof checkFeatureAccess>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getCheckFeatureAccessQueryOptions(
    tenantUuid,
    featureName,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Request access to a specific feature for the authenticated tenant
 * @summary Request Feature Access
 */
export const requestFeatureAccess = (
  tenantUuid: string,
  featureName: FeatureName,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/v1/tenants/${tenantUuid}/features/${featureName}/request-access`,
      method: "POST",
      signal,
    },
    options
  );
};

export const getRequestFeatureAccessMutationOptions = <
  TData = Awaited<ReturnType<typeof requestFeatureAccess>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; featureName: FeatureName },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["requestFeatureAccess"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof requestFeatureAccess>>,
    { tenantUuid: string; featureName: FeatureName }
  > = (props) => {
    const { tenantUuid, featureName } = props ?? {};

    return requestFeatureAccess(tenantUuid, featureName, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; featureName: FeatureName },
    TContext
  >;
};

export type RequestFeatureAccessMutationResult = NonNullable<
  Awaited<ReturnType<typeof requestFeatureAccess>>
>;

export type RequestFeatureAccessMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Request Feature Access
 */
export const useRequestFeatureAccess = <
  TData = Awaited<ReturnType<typeof requestFeatureAccess>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; featureName: FeatureName },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; featureName: FeatureName },
  TContext
> => {
  const mutationOptions = getRequestFeatureAccessMutationOptions(options);

  return useMutation(mutationOptions);
};
