/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  BoostCompetitionLineItemWithBehaviorSchema,
  BoostCompetitionLineItemsSearchResultSchema,
  HTTPValidationError,
  SearchCompetitionLineItemsParams,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Search Competition Line Items
 */
export const searchCompetitionLineItems = (
  tenantUuid: string,
  params?: SearchCompetitionLineItemsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostCompetitionLineItemsSearchResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competition-line-items/search`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getSearchCompetitionLineItemsQueryKey = (
  tenantUuid: string,
  params?: SearchCompetitionLineItemsParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/competition-line-items/search`,
    ...(params ? [params] : []),
  ] as const;
};

export const getSearchCompetitionLineItemsQueryOptions = <
  TData = Awaited<ReturnType<typeof searchCompetitionLineItems>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: SearchCompetitionLineItemsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchCompetitionLineItems>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getSearchCompetitionLineItemsQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchCompetitionLineItems>>
  > = ({ signal }) =>
    searchCompetitionLineItems(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchCompetitionLineItems>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchCompetitionLineItemsQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchCompetitionLineItems>>
>;
export type SearchCompetitionLineItemsQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Search Competition Line Items
 */

export function useSearchCompetitionLineItems<
  TData = Awaited<ReturnType<typeof searchCompetitionLineItems>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: SearchCompetitionLineItemsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchCompetitionLineItems>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getSearchCompetitionLineItemsQueryOptions(
    tenantUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Competition Line Item Details
 */
export const getCompetitionLineItemDetails = (
  tenantUuid: string,
  lineItemUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostCompetitionLineItemWithBehaviorSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/competition-line-items/${lineItemUuid}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetCompetitionLineItemDetailsQueryKey = (
  tenantUuid: string,
  lineItemUuid: string
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/competition-line-items/${lineItemUuid}`,
  ] as const;
};

export const getGetCompetitionLineItemDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCompetitionLineItemDetails>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  lineItemUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompetitionLineItemDetails>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCompetitionLineItemDetailsQueryKey(tenantUuid, lineItemUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCompetitionLineItemDetails>>
  > = ({ signal }) =>
    getCompetitionLineItemDetails(
      tenantUuid,
      lineItemUuid,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && lineItemUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCompetitionLineItemDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCompetitionLineItemDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompetitionLineItemDetails>>
>;
export type GetCompetitionLineItemDetailsQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Get Competition Line Item Details
 */

export function useGetCompetitionLineItemDetails<
  TData = Awaited<ReturnType<typeof getCompetitionLineItemDetails>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  lineItemUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompetitionLineItemDetails>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCompetitionLineItemDetailsQueryOptions(
    tenantUuid,
    lineItemUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
