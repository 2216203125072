/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation } from "@tanstack/react-query";
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";
import type {
  BoostAnnounceInput,
  BoostAnnounceResponse,
  HTTPValidationError,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Boost Send Announcement
 */
export const boostSendAnnouncement = (
  tenantUuid: string,
  boostAnnounceInput: BoostAnnounceInput,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostAnnounceResponse>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/announcements`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: boostAnnounceInput,
      signal,
    },
    options
  );
};

export const getBoostSendAnnouncementMutationOptions = <
  TData = Awaited<ReturnType<typeof boostSendAnnouncement>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: BoostAnnounceInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["boostSendAnnouncement"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof boostSendAnnouncement>>,
    { tenantUuid: string; data: BoostAnnounceInput }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return boostSendAnnouncement(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: BoostAnnounceInput },
    TContext
  >;
};

export type BoostSendAnnouncementMutationResult = NonNullable<
  Awaited<ReturnType<typeof boostSendAnnouncement>>
>;
export type BoostSendAnnouncementMutationBody = BoostAnnounceInput;
export type BoostSendAnnouncementMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Boost Send Announcement
 */
export const useBoostSendAnnouncement = <
  TData = Awaited<ReturnType<typeof boostSendAnnouncement>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: BoostAnnounceInput },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; data: BoostAnnounceInput },
  TContext
> => {
  const mutationOptions = getBoostSendAnnouncementMutationOptions(options);

  return useMutation(mutationOptions);
};
