/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type PersonaType = (typeof PersonaType)[keyof typeof PersonaType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PersonaType = {
  funny: "funny",
  friendly: "friendly",
  professional: "professional",
} as const;
