/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  ActivityCountsParams,
  ActivityCountsPerTypeParams,
  BehaviorDetailsResult,
  BoostDashboardActivityCountsPerTypeResultSchema,
  BoostDashboardActivityCountsResultSchema,
  BoostDashboardMostPointsPerUserResultSchema,
  BoostDashboardMostPopularBehaviorResultSchema,
  BoostDashboardUserStatsResultSchema,
  BoostDashboardUserWithMostPointsResultSchema,
  HTTPValidationError,
  MostPointsPerUserParams,
  MostPopularParams,
  MostPopularSkuParams,
  UserStatsParams,
  UserWithMostPointsParams,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Activity Counts
 */
export const activityCounts = (
  tenantUuid: string,
  params?: ActivityCountsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostDashboardActivityCountsResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/dashboard/activity-counts`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getActivityCountsQueryKey = (
  tenantUuid: string,
  params?: ActivityCountsParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/dashboard/activity-counts`,
    ...(params ? [params] : []),
  ] as const;
};

export const getActivityCountsQueryOptions = <
  TData = Awaited<ReturnType<typeof activityCounts>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: ActivityCountsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof activityCounts>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getActivityCountsQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof activityCounts>>> = ({
    signal,
  }) => activityCounts(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof activityCounts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ActivityCountsQueryResult = NonNullable<
  Awaited<ReturnType<typeof activityCounts>>
>;
export type ActivityCountsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Activity Counts
 */

export function useActivityCounts<
  TData = Awaited<ReturnType<typeof activityCounts>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: ActivityCountsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof activityCounts>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getActivityCountsQueryOptions(
    tenantUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Activity Counts Per Type
 */
export const activityCountsPerType = (
  tenantUuid: string,
  params?: ActivityCountsPerTypeParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostDashboardActivityCountsPerTypeResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/dashboard/activity-counts-per-type`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getActivityCountsPerTypeQueryKey = (
  tenantUuid: string,
  params?: ActivityCountsPerTypeParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/dashboard/activity-counts-per-type`,
    ...(params ? [params] : []),
  ] as const;
};

export const getActivityCountsPerTypeQueryOptions = <
  TData = Awaited<ReturnType<typeof activityCountsPerType>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: ActivityCountsPerTypeParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof activityCountsPerType>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getActivityCountsPerTypeQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof activityCountsPerType>>
  > = ({ signal }) =>
    activityCountsPerType(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof activityCountsPerType>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ActivityCountsPerTypeQueryResult = NonNullable<
  Awaited<ReturnType<typeof activityCountsPerType>>
>;
export type ActivityCountsPerTypeQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Activity Counts Per Type
 */

export function useActivityCountsPerType<
  TData = Awaited<ReturnType<typeof activityCountsPerType>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: ActivityCountsPerTypeParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof activityCountsPerType>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getActivityCountsPerTypeQueryOptions(
    tenantUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Most Popular Sku
 */
export const mostPopularSku = (
  tenantUuid: string,
  params?: MostPopularSkuParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostDashboardMostPopularBehaviorResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/dashboard/most-popular-sku`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getMostPopularSkuQueryKey = (
  tenantUuid: string,
  params?: MostPopularSkuParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/dashboard/most-popular-sku`,
    ...(params ? [params] : []),
  ] as const;
};

export const getMostPopularSkuQueryOptions = <
  TData = Awaited<ReturnType<typeof mostPopularSku>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: MostPopularSkuParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof mostPopularSku>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getMostPopularSkuQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof mostPopularSku>>> = ({
    signal,
  }) => mostPopularSku(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof mostPopularSku>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type MostPopularSkuQueryResult = NonNullable<
  Awaited<ReturnType<typeof mostPopularSku>>
>;
export type MostPopularSkuQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Most Popular Sku
 */

export function useMostPopularSku<
  TData = Awaited<ReturnType<typeof mostPopularSku>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: MostPopularSkuParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof mostPopularSku>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getMostPopularSkuQueryOptions(
    tenantUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary User With Most Points
 */
export const userWithMostPoints = (
  tenantUuid: string,
  params?: UserWithMostPointsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostDashboardUserWithMostPointsResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/dashboard/user-with-most-points`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getUserWithMostPointsQueryKey = (
  tenantUuid: string,
  params?: UserWithMostPointsParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/dashboard/user-with-most-points`,
    ...(params ? [params] : []),
  ] as const;
};

export const getUserWithMostPointsQueryOptions = <
  TData = Awaited<ReturnType<typeof userWithMostPoints>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: UserWithMostPointsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userWithMostPoints>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserWithMostPointsQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userWithMostPoints>>
  > = ({ signal }) =>
    userWithMostPoints(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof userWithMostPoints>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type UserWithMostPointsQueryResult = NonNullable<
  Awaited<ReturnType<typeof userWithMostPoints>>
>;
export type UserWithMostPointsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary User With Most Points
 */

export function useUserWithMostPoints<
  TData = Awaited<ReturnType<typeof userWithMostPoints>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: UserWithMostPointsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userWithMostPoints>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getUserWithMostPointsQueryOptions(
    tenantUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Most Points Per User
 */
export const mostPointsPerUser = (
  tenantUuid: string,
  params?: MostPointsPerUserParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostDashboardMostPointsPerUserResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/dashboard/most-points-per-user`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getMostPointsPerUserQueryKey = (
  tenantUuid: string,
  params?: MostPointsPerUserParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/dashboard/most-points-per-user`,
    ...(params ? [params] : []),
  ] as const;
};

export const getMostPointsPerUserQueryOptions = <
  TData = Awaited<ReturnType<typeof mostPointsPerUser>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: MostPointsPerUserParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof mostPointsPerUser>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getMostPointsPerUserQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof mostPointsPerUser>>
  > = ({ signal }) =>
    mostPointsPerUser(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof mostPointsPerUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type MostPointsPerUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof mostPointsPerUser>>
>;
export type MostPointsPerUserQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Most Points Per User
 */

export function useMostPointsPerUser<
  TData = Awaited<ReturnType<typeof mostPointsPerUser>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: MostPointsPerUserParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof mostPointsPerUser>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getMostPointsPerUserQueryOptions(
    tenantUuid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary User Stats
 */
export const userStats = (
  tenantUuid: string,
  params?: UserStatsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostDashboardUserStatsResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/dashboard/user-stats`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getUserStatsQueryKey = (
  tenantUuid: string,
  params?: UserStatsParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/dashboard/user-stats`,
    ...(params ? [params] : []),
  ] as const;
};

export const getUserStatsQueryOptions = <
  TData = Awaited<ReturnType<typeof userStats>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: UserStatsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userStats>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserStatsQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof userStats>>> = ({
    signal,
  }) => userStats(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof userStats>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type UserStatsQueryResult = NonNullable<
  Awaited<ReturnType<typeof userStats>>
>;
export type UserStatsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary User Stats
 */

export function useUserStats<
  TData = Awaited<ReturnType<typeof userStats>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: UserStatsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof userStats>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getUserStatsQueryOptions(tenantUuid, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Most Popular
 */
export const mostPopular = (
  tenantUuid: string,
  params?: MostPopularParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BehaviorDetailsResult>(
    {
      url: `/api/v1/tenants/${tenantUuid}/boost/dashboard/details-by-behavior-type`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getMostPopularQueryKey = (
  tenantUuid: string,
  params?: MostPopularParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/boost/dashboard/details-by-behavior-type`,
    ...(params ? [params] : []),
  ] as const;
};

export const getMostPopularQueryOptions = <
  TData = Awaited<ReturnType<typeof mostPopular>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: MostPopularParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof mostPopular>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getMostPopularQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof mostPopular>>> = ({
    signal,
  }) => mostPopular(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof mostPopular>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type MostPopularQueryResult = NonNullable<
  Awaited<ReturnType<typeof mostPopular>>
>;
export type MostPopularQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Most Popular
 */

export function useMostPopular<
  TData = Awaited<ReturnType<typeof mostPopular>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: MostPopularParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof mostPopular>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getMostPopularQueryOptions(tenantUuid, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
