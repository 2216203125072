/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  BoostUserCanvasTokenSchema,
  HTTPValidationError,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @deprecated
 * @summary Get Canvas Token
 */
export const getCanvasToken = (
  tenantUuid: string,
  userUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostUserCanvasTokenSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/users/${userUuid}/canvas-token`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetCanvasTokenQueryKey = (
  tenantUuid: string,
  userUuid: string
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/users/${userUuid}/canvas-token`,
  ] as const;
};

export const getGetCanvasTokenQueryOptions = <
  TData = Awaited<ReturnType<typeof getCanvasToken>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  userUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCanvasToken>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCanvasTokenQueryKey(tenantUuid, userUuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCanvasToken>>> = ({
    signal,
  }) => getCanvasToken(tenantUuid, userUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && userUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCanvasToken>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCanvasTokenQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCanvasToken>>
>;
export type GetCanvasTokenQueryError = ErrorType<HTTPValidationError>;

/**
 * @deprecated
 * @summary Get Canvas Token
 */

export function useGetCanvasToken<
  TData = Awaited<ReturnType<typeof getCanvasToken>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  userUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCanvasToken>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCanvasTokenQueryOptions(
    tenantUuid,
    userUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Canvas User Token
 */
export const getCanvasUserToken = (
  tenantUuid: string,
  userUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostUserCanvasTokenSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/tokens/users/${userUuid}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetCanvasUserTokenQueryKey = (
  tenantUuid: string,
  userUuid: string
) => {
  return [`/api/v1/tenants/${tenantUuid}/tokens/users/${userUuid}`] as const;
};

export const getGetCanvasUserTokenQueryOptions = <
  TData = Awaited<ReturnType<typeof getCanvasUserToken>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  userUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCanvasUserToken>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCanvasUserTokenQueryKey(tenantUuid, userUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCanvasUserToken>>
  > = ({ signal }) =>
    getCanvasUserToken(tenantUuid, userUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && userUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCanvasUserToken>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCanvasUserTokenQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCanvasUserToken>>
>;
export type GetCanvasUserTokenQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Canvas User Token
 */

export function useGetCanvasUserToken<
  TData = Awaited<ReturnType<typeof getCanvasUserToken>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  userUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCanvasUserToken>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCanvasUserTokenQueryOptions(
    tenantUuid,
    userUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Canvas Competition Token
 */
export const getCanvasCompetitionToken = (
  tenantUuid: string,
  competitionUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<BoostUserCanvasTokenSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/tokens/competitions/${competitionUuid}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetCanvasCompetitionTokenQueryKey = (
  tenantUuid: string,
  competitionUuid: string
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/tokens/competitions/${competitionUuid}`,
  ] as const;
};

export const getGetCanvasCompetitionTokenQueryOptions = <
  TData = Awaited<ReturnType<typeof getCanvasCompetitionToken>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCanvasCompetitionToken>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCanvasCompetitionTokenQueryKey(tenantUuid, competitionUuid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCanvasCompetitionToken>>
  > = ({ signal }) =>
    getCanvasCompetitionToken(
      tenantUuid,
      competitionUuid,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && competitionUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCanvasCompetitionToken>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCanvasCompetitionTokenQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCanvasCompetitionToken>>
>;
export type GetCanvasCompetitionTokenQueryError =
  ErrorType<HTTPValidationError>;

/**
 * @summary Get Canvas Competition Token
 */

export function useGetCanvasCompetitionToken<
  TData = Awaited<ReturnType<typeof getCanvasCompetitionToken>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  competitionUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCanvasCompetitionToken>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCanvasCompetitionTokenQueryOptions(
    tenantUuid,
    competitionUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
