/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type FeatureStatus = (typeof FeatureStatus)[keyof typeof FeatureStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeatureStatus = {
  enabled: "enabled",
  disabled: "disabled",
  demo: "demo",
} as const;
