/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  CampaignCreateSchema,
  CampaignSchema,
  CampaignUpdateSchema,
  CampaignsSearchResultSchema,
  GetCampaignsParams,
  HTTPValidationError,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get Campaigns
 */
export const getCampaigns = (
  tenantUuid: string,
  params?: GetCampaignsParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CampaignsSearchResultSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns/search`,
      method: "GET",
      params,
      signal,
    },
    options
  );
};

export const getGetCampaignsQueryKey = (
  tenantUuid: string,
  params?: GetCampaignsParams
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns/search`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetCampaignsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCampaigns>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: GetCampaignsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCampaigns>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCampaignsQueryKey(tenantUuid, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCampaigns>>> = ({
    signal,
  }) => getCampaigns(tenantUuid, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCampaigns>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCampaignsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCampaigns>>
>;
export type GetCampaignsQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Campaigns
 */

export function useGetCampaigns<
  TData = Awaited<ReturnType<typeof getCampaigns>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  params?: GetCampaignsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCampaigns>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCampaignsQueryOptions(tenantUuid, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Campaign
 */
export const getCampaign = (
  tenantUuid: string,
  campaignUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CampaignSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns/${campaignUuid}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetCampaignQueryKey = (
  tenantUuid: string,
  campaignUuid: string
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns/${campaignUuid}`,
  ] as const;
};

export const getGetCampaignQueryOptions = <
  TData = Awaited<ReturnType<typeof getCampaign>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  campaignUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCampaign>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCampaignQueryKey(tenantUuid, campaignUuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCampaign>>> = ({
    signal,
  }) => getCampaign(tenantUuid, campaignUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && campaignUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCampaign>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCampaignQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCampaign>>
>;
export type GetCampaignQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Campaign
 */

export function useGetCampaign<
  TData = Awaited<ReturnType<typeof getCampaign>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  campaignUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCampaign>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetCampaignQueryOptions(
    tenantUuid,
    campaignUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update Campaign
 */
export const updateCampaign = (
  tenantUuid: string,
  campaignUuid: string,
  campaignUpdateSchema: CampaignUpdateSchema,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<CampaignSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns/${campaignUuid}`,
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      data: campaignUpdateSchema,
    },
    options
  );
};

export const getUpdateCampaignMutationOptions = <
  TData = Awaited<ReturnType<typeof updateCampaign>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; campaignUuid: string; data: CampaignUpdateSchema },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["updateCampaign"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCampaign>>,
    { tenantUuid: string; campaignUuid: string; data: CampaignUpdateSchema }
  > = (props) => {
    const { tenantUuid, campaignUuid, data } = props ?? {};

    return updateCampaign(tenantUuid, campaignUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; campaignUuid: string; data: CampaignUpdateSchema },
    TContext
  >;
};

export type UpdateCampaignMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCampaign>>
>;
export type UpdateCampaignMutationBody = CampaignUpdateSchema;
export type UpdateCampaignMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Update Campaign
 */
export const useUpdateCampaign = <
  TData = Awaited<ReturnType<typeof updateCampaign>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; campaignUuid: string; data: CampaignUpdateSchema },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; campaignUuid: string; data: CampaignUpdateSchema },
  TContext
> => {
  const mutationOptions = getUpdateCampaignMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Create Campaign
 */
export const createCampaign = (
  tenantUuid: string,
  campaignCreateSchema: CampaignCreateSchema,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<CampaignSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/campaigns`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: campaignCreateSchema,
      signal,
    },
    options
  );
};

export const getCreateCampaignMutationOptions = <
  TData = Awaited<ReturnType<typeof createCampaign>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: CampaignCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const mutationKey = ["createCampaign"];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      "mutationKey" in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCampaign>>,
    { tenantUuid: string; data: CampaignCreateSchema }
  > = (props) => {
    const { tenantUuid, data } = props ?? {};

    return createCampaign(tenantUuid, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions } as UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: CampaignCreateSchema },
    TContext
  >;
};

export type CreateCampaignMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCampaign>>
>;
export type CreateCampaignMutationBody = CampaignCreateSchema;
export type CreateCampaignMutationError = ErrorType<HTTPValidationError>;

/**
 * @summary Create Campaign
 */
export const useCreateCampaign = <
  TData = Awaited<ReturnType<typeof createCampaign>>,
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    TData,
    TError,
    { tenantUuid: string; data: CampaignCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  TData,
  TError,
  { tenantUuid: string; data: CampaignCreateSchema },
  TContext
> => {
  const mutationOptions = getCreateCampaignMutationOptions(options);

  return useMutation(mutationOptions);
};
