/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type BoostUserAndProfileSchemaRole =
  (typeof BoostUserAndProfileSchemaRole)[keyof typeof BoostUserAndProfileSchemaRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BoostUserAndProfileSchemaRole = {
  admin: "admin",
  technician: "technician",
  admin_technician: "admin_technician",
  lead_gen: "lead_gen",
} as const;
