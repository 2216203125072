/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  HTTPValidationError,
  PersonaSchema,
  PersonasSchema,
} from ".././schema";
import { axiosInstance } from "../../lib/axios";
import type { ErrorType } from "../../lib/axios";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Get all personas
 * @summary Get Personas
 */
export const getPersonas = (
  tenantUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PersonasSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/personas`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetPersonasQueryKey = (tenantUuid: string) => {
  return [`/api/v1/tenants/${tenantUuid}/lead-gen/personas`] as const;
};

export const getGetPersonasQueryOptions = <
  TData = Awaited<ReturnType<typeof getPersonas>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPersonas>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPersonasQueryKey(tenantUuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPersonas>>> = ({
    signal,
  }) => getPersonas(tenantUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!tenantUuid,
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPersonas>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPersonasQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPersonas>>
>;
export type GetPersonasQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Personas
 */

export function useGetPersonas<
  TData = Awaited<ReturnType<typeof getPersonas>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPersonas>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPersonasQueryOptions(tenantUuid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get a specific persona by UUID
 * @summary Get Persona
 */
export const getPersona = (
  tenantUuid: string,
  personaUuid: string,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<PersonaSchema>(
    {
      url: `/api/v1/tenants/${tenantUuid}/lead-gen/personas/${personaUuid}`,
      method: "GET",
      signal,
    },
    options
  );
};

export const getGetPersonaQueryKey = (
  tenantUuid: string,
  personaUuid: string
) => {
  return [
    `/api/v1/tenants/${tenantUuid}/lead-gen/personas/${personaUuid}`,
  ] as const;
};

export const getGetPersonaQueryOptions = <
  TData = Awaited<ReturnType<typeof getPersona>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  personaUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPersona>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPersonaQueryKey(tenantUuid, personaUuid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPersona>>> = ({
    signal,
  }) => getPersona(tenantUuid, personaUuid, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tenantUuid && personaUuid),
    refetchOnWindowFocus: "always",
    staleTime: Infinity,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPersona>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPersonaQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPersona>>
>;
export type GetPersonaQueryError = ErrorType<HTTPValidationError>;

/**
 * @summary Get Persona
 */

export function useGetPersona<
  TData = Awaited<ReturnType<typeof getPersona>>,
  TError = ErrorType<HTTPValidationError>,
>(
  tenantUuid: string,
  personaUuid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPersona>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPersonaQueryOptions(
    tenantUuid,
    personaUuid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
