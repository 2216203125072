/**
 * Generated by orval v7.4.1 🍺
 * Do not edit manually.
 * UpSmith Staging API
 * OpenAPI spec version: 1.0.0
 */

export type CampaignStatus =
  (typeof CampaignStatus)[keyof typeof CampaignStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CampaignStatus = {
  active: "active",
  inactive: "inactive",
} as const;
